
.sidebar-in{
    animation: open 0.2s ease-in;
}

@keyframes open {
    0% {
      left: -240px;
    }
    100% {
      left: 0px;
    }
  }