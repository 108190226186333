@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./components/navigation/nav-animations.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #4E4E4E;
}
